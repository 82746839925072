import React from "react"
import PropTypes from "prop-types"
import { Layout } from "antd"

const { Content } = Layout

const Container = ({ children }) => {
	return (
		<Layout
			style={{
				height: "100%",
				background: "white",
			}}
		>
			<Layout style={{ background: "white" }}>
				<Content
					style={{
						// maxWidth : 1440,
						width: "100%",
						// marginTop: 64,
						// minHeight: "100vh", //edit this to change minimum page height
					}}
				>
					<div
						style={{
							/*maxWidth: "900px",*/ width: "100%",
							margin: "0 auto",
						}}
					>
						{children}
					</div>
				</Content>
			</Layout>
		</Layout>
	)
}
Container.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Container
