import React from "react"
import Container from "../components/empty"
import SEO from "../components/seo"
import withRoot from "../withRoot"
import { navigate } from "gatsby"
import _ from "lodash"

const NotFoundPage = props => {
	const { location } = props
	const isBrowser = typeof window !== "undefined"

	const trunks = _.split(_.trimStart(location.pathname, "/"), "/")
	if (_.startsWith(location.pathname, "/property")) {
		const id = _.last(_.split(trunks[0], "-"))
		const lasts = _.tail(trunks)
		if (id && isBrowser) {
			navigate(
				lasts.length > 0
					? `/property/${id}/${_.join(lasts, "/")}`
					: `/property/${id}`,
				{
					state: {
						rawPath: location.pathname,
					},
				}
			)
			return null
		}
	} else if (_.startsWith(location.pathname, "/project")) {
		const id = _.last(_.split(trunks[0], "-"))
		const lasts = _.tail(trunks)
		if (id && isBrowser) {
			navigate(
				lasts.length > 0
					? `/project/${id}/${_.join(lasts, "/")}`
					: `/project/${id}`,
				{
					state: { rawPath: location.pathname },
				}
			)
			return null
		}
	}

	return (
		<Container defKey="2">
			<SEO title="404: Not found" />
		</Container>
	)
}

export default withRoot(NotFoundPage)
